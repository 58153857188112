import React from 'react';
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';
import classes from './SundaySchoolPage.module.css';
const SundaySchoolPage = () => {
    return (
        <div style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #ECECEC 99.99%)'}}>
            <Header/>
            <div className={classes.Title}>Воскресная школа для взрослых</div>
            <div className={classes.Text}><p>При храме Рождества Христова в Крутой горке действует воскресная школа для взрослых.</p>

            <p>Воскресная школа для взрослых помогает прихожанам воцерковляться, приобщает их к литургической жизни храма. Многие из нас, крещёные в детстве, не получили должного духовного воспитания. Воскресная школа помогает человеку восполнить этот пробел, понять смысл христианской жизни.
Уроки проходят в форме общения преподавателя и прихожан: вначале разбирается тема урока, а затем проходит ее обсуждение и каждый может задать интересующий его вопрос.
На занятиях изучается Священное Писание Ветхого и Нового Завета, разбираются церковные службы, рассматриваются основные положения в учении о Церкви, изучаются божественные заповеди и значение каждой службы.
</p>
<p>Занятия проходят в нашем храме по воскресным дням в 15.00 часов.</p></div>
            <Footer/>
        </div>
    );
}

export default SundaySchoolPage;
